import React, { useState } from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DeliveryModal from './DeliveryModal'
import { hero, mobileHero, orderDeliveryButton } from 'images'

export default () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="75VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView textCenter column center w="100%" ph="7%" pt="10px" pb="5px">
            {/* <CFView column center>
              <CFText
                color="white"
                h1
                style={{
                  fontSize: 32,
                }}
                raleway
                bold
              >
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView> */}
            <CFView mv="12px">
              <CFView pulsate>
                <OrderPickupButton />
              </CFView>
              {/* <CFView hover onClick={() => setShowModal(true)} column center>
                <CFImage
                  w="100%"
                  mv="10px"
                  maxWidth="280px"
                  src={orderDeliveryButton}
                  alt="Order delivery"
                />
              </CFView> */}
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            column
            center
            w="100%"
            pv="10px"
            maxWidth="1400px"
            bg="rgba(0, 0, 0, 0.8)"
            style={{
              backdropFilter: 'blur(10px)',
              webkitBackdropFilter: 'blur(10px)',
            }}
          >
            <CFView mb="5px">
              <CFText
                color="white"
                style={{
                  fontSize: 36,
                }}
                raleway
                bold
              >
                WELCOME TO SUSHICO LANGLEY
              </CFText>
            </CFView>
            <CFView row center pt="5px">
              <CFView pulsate>
                <OrderPickupButton />
              </CFView>
              {/* <CFView
                w="90%"
                hover
                onClick={() => setShowModal(true)}
                ml="30px"
              >
                <CFImage
                  maxWidth="300px"
                  src={orderDeliveryButton}
                  alt="Order delivery"
                />
              </CFView> */}
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>{' '}
      <DeliveryModal
        showModal={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </CFView>
  )
}

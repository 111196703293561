import React from 'react'
import { css } from 'emotion'
import { CFImage, CFView, PrimaryModal } from 'components'
import { doorDash, skipTheDishes, uberEats } from 'images'

export default props => {
  return (
    <PrimaryModal
      title={'Choose Delivery Provider'}
      isOpen={props.showModal}
      onRequestClose={props.onRequestClose}
    >
      <CFView className={styles.container}>
        <CFView column alignCenter w="100%">
          <a href={`https://www.doordash.com/store/sushico-langley-362139/`}>
            <CFView hover>
              <CFImage maxWidth="290px" src={doorDash} alt="Door Dash" />
            </CFView>
          </a>
          <a href={`https://www.skipthedishes.com/sushico-langley`}>
            <CFView hover mt="15px">
              <CFImage
                maxWidth="290px"
                src={skipTheDishes}
                alt="Skip the Dishes "
              />
            </CFView>
          </a>
          <a
            href={`https://www.ubereats.com/ca/store/sushico/oTcurVhqRrCtNAROoPIxTQ?diningMode=DELIVERY&utm_source=AdWords_NonBrand&utm_campaign=CM2049465-search-google-nonbrand_32_-99_CA-National_e_all_acq_cpc_en_DSA_Exact__dsa-1136820532232_459292784604_110469673827__c&campaign_id=10949109101&adg_id=110469673827&fi_id=&match=&net=g&dev=c&dev_m=&ad_id=459292784604&cre=459292784604&kwid=dsa-1136820532232&kw=&placement=&tar=&gclid=CjwKCAiAyPyQBhB6EiwAFUuakibM4WXnYomz7ORgz2tOkZs_3UthppOuUjfw7wZ0V87y_nFC-xIyGhoCDiEQAvD_BwE&gclsrc=aw.ds`}
          >
            <CFView hover mt="15px">
              <CFImage maxWidth="290px" src={uberEats} alt="UberEats" />
            </CFView>
          </a>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      padding: '30px',
      width: '330px',
    },
  }),
}
